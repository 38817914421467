import React from 'react'
import styles from '../../styles/Home.module.css'
import CommonButton from '../Common/commonButton'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Intro = () => {
  return (
    <div className={styles.homeMobileContainer}>
      <div className={styles.homeMobileTile}>
        <h2>
          <span style={{ fontWeight: 700, fontFamily: 'EnzoBold' }}>HR.</span> Now Affordable to Everyone
        </h2>
        <p>
          WebHR covers everything from &quot;Hire&quot; to &quot;Retire&quot; for the most
          <br />
          important asset in your company - your people - your workforce!
        </p>
      </div>

      <div className={styles.homeMobileView}>
        <LazyLoadImage src="https://cdn.webhr.co/Website/images/landing_page/Mobile.png" className={styles.MobileResponsive} />
        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', marginBlock: 10 }}>
          <CommonButton link={'/pricing'} header Text={'Try WebHR for free'} style={{ paddingInline: 30 }} />
        </div>
      </div>
    </div>
  )
}

export default React.memo(Intro)
